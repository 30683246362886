import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import {} from 'react-router-dom'
  


class Header extends Component {

    componentDidMount() {

        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var aaa = document.querySelector('.myNavbar ');

        function toggleFunc() {
            return aaa.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            navUl.forEach(el => el.classList.remove('open'));
            current.classList.add('open');
        }

    }
    render() {
        return (
            <header className="site-header mo-left header">
                <div className="top-bar bg-dark">
                    <div className="container">
                        <div className="row d-flex justify-content-between">
                            <div className="dlab-topbar-left">
                                <ul>
                                    <li>
                                        <Link to={'/'} className="site-button-link"> Home </Link>
                                    </li>
                                    <li>
                                        <Link className="site-button-link" to={'/place'}>Places</Link>
                                    </li>
                                    <li><Link className="site-button-link" to={'/activities'}>Activities</Link></li>
                                </ul>
                            </div>
                            <div className="dlab-topbar-right top-login">
                                <ul>
                                    <li><Link to={'./'} className="site-button-link">Login</Link></li>
                                    <li><Link to={'./'} className="site-button-link">Register</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-header navbar-expand-lg">
                    <div className="main-bar clearfix onepage">
                        <div className="container clearfix">
                            <div className="logo-header mostion">
                                <Link to={'./'} ><img src={require('./../../images/logo.png')} alt="" /></Link>
                            </div>
                            <button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                            <div className="header-nav navbar-collapse collapse navbar myNavbar justify-content-end" id="navbarNavDropdown">
                                <ul className="nav navbar-nav">
                                    <li><Link to={'/'}>Home</Link>
                                    </li>
                                    <li><Link to={''}> Accomodation <i className="fa fa-chevron-down"></i></Link>
                                        <ul className="sub-menu">
                                            <li><Link className="dez-page">Bed & Breakfast</Link></li>
                                            <li><Link className="dez-page">Hotels </Link></li>
                                            <li><Link>Lodges</Link></li>
                                            <li><Link>Villas</Link></li>
                                            <li><Link>Camps</Link></li>
                                            <li><Link>Backpackers</Link></li>
                                            <li><Link className="dez-page">Shared Hosting</Link></li>
                                            <li><Link className="dez-page">Apartements</Link></li>
                                            <li><Link className="dez-page">Digital Nomads</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link>Flights</Link></li>
                                    <li><Link>Cabs and Car Rentals</Link></li>
                                    <li><Link to={''}> Eating Out <i className="fa fa-chevron-down"></i></Link>
                                        <ul className="sub-menu">
                                            <li><Link className="dez-page">Restaurants</Link></li>
                                            <li><Link className="dez-page">Coffee House </Link></li>
                                            <li><Link>Cafes</Link></li>
                                            <li><Link>Fast Foods</Link></li>
                                            <li><Link>On demand delivery</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to={''}> Tourism<i className="fa fa-chevron-down"></i></Link>
                                        <ul className="sub-menu">
                                            <li><Link className="dez-page">Conservation</Link></li>
                                            <li><Link className="dez-page">Enivironmental</Link></li>
                                            <li><Link>Historical</Link></li>
                                            <li><Link>Culture & Heritage</Link></li>
                                            <li><Link>Conservancies</Link></li>
                                            <li><Link>Medical</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to={''}> Holidays<i className="fa fa-chevron-down"></i></Link>
                                        <ul className="sub-menu">
                                            <li><Link className="dez-page">Beach & Bush Safaris</Link></li>
                                            <li><Link className="dez-page">Game Drives</Link></li>
                                            <li><Link>Sun & Sand</Link></li>
                                            <li><Link>Hot Air Baloons</Link></li>
                                            <li><Link>Cruise Ships</Link></li>
                                            <li><Link>Overlander</Link></li>
                                            <li><Link>Road Trips</Link></li>
                                            <li><Link>Honeymoons</Link></li>
                                            <li><Link>Hideaways</Link></li>
                                            <li><Link>Getaways</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to={''}> Adventures<i className="fa fa-chevron-down"></i></Link>
                                        <ul className="sub-menu">
                                            <li><Link className="dez-page">Mountain Climbing</Link></li>
                                            <li><Link className="dez-page">Chimps & Gorilla Tracking</Link></li>
                                            <li><Link>Boat Cruise</Link></li>
                                            <li><Link>Waterfalls</Link></li>
                                            <li><Link>Camping</Link></li>
                                            <li><Link>Caves Escapades</Link></li>
                                            <li><Link>Bornfires</Link></li>
                                            <li><Link>Nature Trails</Link></li>
                                            <li><Link>Hill Hiking</Link></li>
                                            <li><Link>Canoeing</Link></li>
                                            <li><Link>Scuba Diving</Link></li>
                                            <li><Link>Bungee Jumping</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to={''}> Sports<i className="fa fa-chevron-down"></i></Link>
                                        <ul className="sub-menu">
                                            <li><Link className="dez-page">Golf</Link></li>
                                            <li><Link className="dez-page">Safari Rally</Link></li>
                                            <li><Link>Tour De Rwanda</Link></li>
                                            <li><Link>Marathon</Link></li>
                                            <li><Link>Biking</Link></li>
                                            <li><Link>Watersports</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to={''}> Events<i className="fa fa-chevron-down"></i></Link>
                                        <ul className="sub-menu">
                                            <li><Link className="dez-page">Mice</Link></li>
                                            <li><Link className="dez-page">Expos</Link></li>
                                            <li><Link>Trade Fairs</Link></li>
                                            <li><Link>Shows</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to={''}> Entertainment<i className="fa fa-chevron-down"></i></Link>
                                        <ul className="sub-menu">
                                            <li><Link className="dez-page">Clubs</Link></li>
                                            <li><Link className="dez-page">Cassinos</Link></li>
                                            <li><Link>Resorts</Link></li>
                                            <li><Link>Art Galleries</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;