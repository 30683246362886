import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';

const packageContent = [
    {
        image: require('./../../images/gallery/img4.jpg'),
        title: 'Hill Hiking',
        subtitle: 'Nature Trails, Bush safaris',
        offers: 1300,
    },
    {
        image: require('./../../images/gallery/img5.jpg'),
        title: 'Boat and Ship Cruise',
        subtitle: 'Cafes, canoeing, scuba diving...',
        offers: 2400,
    },
    {
        image: require('./../../images/gallery/img6.jpg'),
        title: 'Getaways',
        subtitle: 'Hideaways, honeymoons, Game drives',
        offers: 700,
    },
    {
        image: require('./../../images/gallery/img7.jpg'),
        title: 'Camping',
        subtitle: 'Caves Escapade, Eating out, Bornfires',
        offers: 10000,
    },
    {
        image: require('./../../images/gallery/img8.jpg'),
        title: 'Sun And Sand',
        subtitle: 'Beach Soccer, Culture & Heritage, Conservancies',
        offers: 600,
    }
]


var bg3 = require('./../../images/banner/bnr1.jpg');
class Activities extends Component {

    render() {
        return (
            <div>
                <Header />
                <div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">Popular Activities</h1>
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link to={'./'}>Home</Link></li>
                                    <li>Activities</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-full bg-white content-inner dlab-about-1 promotions">
                    <div className="container">
                        <div className="row packages">
                            {packageContent.map((item, index) => (
                                <div className="col-md-6 col-xl-3  col-sm-6 m-b20" key={index}>
                                        <div className="dlab-box">
                                            <div className="dlab-media">
                                                <Link to={'./activities'}><img src={item.image} alt="" /> </Link>
                                            </div>
                                            <div className="dlab-info p-a15 border-1">
                                                <h4 className="dlab-title m-t0"><a>{item.title}</a></h4>
                                                <span className="location">{item.subtitle}</span>
                                                <div className="package-content">
                                                    <ul className="package-meta">
                                                        <li><span className="fa fa-calendar"></span> Offers: {item.offers} </li>
                                                    </ul>
                                                    <div className="clearfix">
                                                        <Link to={'/activities'} className="site-button pull-right">View details</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            ))}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Activities;