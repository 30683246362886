import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios'


const places = [
    {
        price: 1200,
        image: require('./../../images/our-work/pic1.jpg'),
        title: 'The Great Mkapa Bridge, Tanzania',
        description:
            'You want to get the real taste of adrenaline? You want to feel the anxiety of heights?...',
        link: 'booking',
    },
    {
        price: 1200,
        image: require('./../../images/our-work/pic2.jpg'),
        title: 'New Mwanza City, Kenya',
        description:
            'We are looking to fast forward your fantasy into 2030 and experience the new modern Mwanza City...',
        link: 'booking',
    },
    {
        price: 1200,
        image: require('./../../images/our-work/pic3.jpg'),
        title: 'Mombasa, Kenya',
        description:
            'Enjoy the sandy beaches, the cool waters of Mombasa and and its warm breezes that never cease to impress...',
        link: 'booking',
    },
    {
        price: 1200,
        image: require('./../../images/our-work/pic4.jpg'),
        title: 'The Great River Nile, Uganda',
        description:
            'Ever heard of the greatest river in Africa? Wanna get its full view? Wanna ride in it? Well. This is your chance to get some real taste of adventure...',
        link: 'booking',
    },
    {
        price: 1200,
        image: require('./../../images/our-work/pic5.jpg'),
        title: 'Mwanza, Tanzania',
        description:
            'If your looking to experience the real taste of culture. then this is your shot. Mwanza City will provide you with refreshments and entertainment...',
        link: 'booking',
    },
    {
        price: 1500,
        image: require('./../../images/our-work/pic6.jpg'),
        title: 'Zanzibar, Tanzania',
        description:
            'Zanzibar is renowned for its fascinating history and its incredible beaches. Located off the coast of Tanzania and surrounded by the azure waters...',
        link: 'booking',
    },

];
const numbers = [
        {
            price:23,
            ttt:'four'
        },
         {
            price:26,
            ttt:'dfgh'
        },
         {
            price:78,
            ttt:'frtgh'
        },
         {
            price:98,
            ttt:'yjg'
        }
        ];


class TopPlaces extends Component {
    state = {
        loading:true,
        cities:[],
        posta:"jj"
    };
     
     
    async componentDidMount(){
        const url = "http://localhost/trripier/triper/api/top_places.php";
        const response = await fetch(url);
        const data =  await response.json();
        // console.log(data);
        this.setState({cities:data});

        /*axios.post("http://localhost/trripier/triper/public/script/contact.php", this.state.posta)
        .then(response=>{
            console.log(response);
        })
        .catch(error=>{
            console.log(error);
        })*/

    }
    
    

    render() {
        return (
            <div className="section-full bg-white content-inner dlab-about-1 promotions" id="about-us">
                <div className="container" id="top-places">
                    <div className="section-head text-center">
                        <h2 className="text-uppercase m-b0">TOP PLACES</h2>
                        <p className="font-18">BEST PLACES YOU WANNA TRAVEL TO</p>
                        
                        <div>
                            {/*this.state.loading ? <div>loading...</div> : <div>cities..</div>*/ }
                            
                           
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-md-12 col-sm-12 col-lg-12 form-group align-self-center text-left">
                        {this.state.cities.map((item, index) => (
                            <button className="site-button m-b5 mr-1" key={index}>{item.name}</button>                            
                        ))}
                        </div>
                        {/*<div className="col-md-4 col-sm-4 col-lg-3 form-group">
                            <select className="form-control">
                                <option>Relevence</option>
                                <option>High to Low</option>
                                <option>Low to High</option>
                            </select>
                        </div>*/}
                    </div>
                    <div className="row" id="masonry">
                        {places.map((item, index) => (

                            <div className="col-lg-4 col-md-6 col-sm-6 m-b30 card-container" key={index}>
                                <div className="dlab-box">
                                    <div className="dlab-media">
                                        <Link to={{ pathname: "/booking", state: { prodIndex: item.title }}}>
                                            <img src={item.image} alt="" />
                                        </Link>
                                        {/*<div className="tr-price">
                                                                                    <span>{item.price} Ksh</span>
                                                                                </div>*/}
                                    </div>
                                    <div className="dlab-info p-a20 border-1 text-center">
                                        <h4 className="dlab-title m-t0"><Link to={{ pathname: "/booking", state: { prodIndex: item.title }}}>{item.title}</Link></h4>
                                        <p>{item.description}</p>

                                        <div className="tr-btn-info">
                                            <Link
                                            to={{ pathname: "/booking", state: { prodIndex: item.title }}}
                                             className="site-button radius-no"><i className="fa fa-info-circle" aria-hidden="true"></i> More</Link>
                                            <Link className="site-button bg-primary-dark radius-no"><i className="fa fa-location-arrow" aria-hidden="true"></i>  Visit </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}
export default TopPlaces;