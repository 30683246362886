import React, { Component } from 'react'
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import { Link } from 'react-router-dom';
import Slick from './Slick';
// import Activites from './Activites';
import TopPlaces from './../Element/TopPlaces';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import axios from 'axios'




const content = [
    {
        title: 'Experience Africa Like Never',
        button: 'Read More',
        image: require('./../../images/main-slider/slide1.jpg'),
    },
    {
        title: 'From Unforgettable Tourism to Super Cool Events',
        button: 'Discover',
        image: require('./../../images/main-slider/slide2.jpg'),
    },
    {
        title: 'Dont Be Afraid to Take Any Chances',
        button: 'Discover',
        image: require('./../../images/main-slider/slide3.jpg'),
    }
];
const popularActivities = [
    {
        image: require('./../../images/gallery/img4.jpg'),
        title: 'Hill Hiking',
        subtitle: 'Nature Trails, Bush safaris',
        offers: 1300,
    },
    {
        image: require('./../../images/gallery/img5.jpg'),
        title: 'Boat and Ship Cruise',
        subtitle: 'Cafes, canoeing, scuba diving...',
        offers: 2400,
    },
    {
        image: require('./../../images/gallery/img6.jpg'),
        title: 'Getaways',
        subtitle: 'Hideaways, honeymoons, Game drives',
        offers: 700,
    },
    {
        image: require('./../../images/gallery/img7.jpg'),
        title: 'Camping',
        subtitle: 'Caves Escapade, Eating out, Bornfires',
        offers: 10000,
    },
    {
        image: require('./../../images/gallery/img8.jpg'),
        title: 'Sun And Sand',
        subtitle: 'Beach Soccer, Culture & Heritage, Conservancies',
        offers: 600,
    }
]

var bg1 = require('./../../images/background/bg1.jpg');
var bg3 = require('./../../images/background/bg3.jpg');

class Homepage extends Component {

    state = {
        keywords:'',
        activity:'',
        destination:'',
        response:''
    };

    changeHandler = e =>{
        this.setState({[e.target.name]:e.target.value})
    };

    submitHandler = e =>{
        e.preventDefault();
        console.log(this.state);

        axios.post("http://localhost/trripier/triper/public/script/search.php", this.state)
        .then(response=>{
            this.setState({response:response.data});
            alert("The Search function is not available at this time as there is not enough data in the system")
            // console.log(this.state.response);
        })
        .catch(error=>{
            console.log(error);
        })
    };

    render() {
        const {keywords,activity,destination} = this.state;

        return (
            <div>
                <Header />
                <div className="page-content">

                    <Slider className="slider-wrapper" infinite autoplay>
                        {content.map((item, index) => (
                            <div
                                key={index}
                                className="slider-content"
                                style={{ background: `url('${item.image}') no-repeat center center` }}
                            >
                                <div className="inner">
                                    <h1>{item.title}</h1>
                                    <p>{item.description}</p>
                                    <a href="#top-places"><button className="site-button" >{item.button}</button></a>
                                </div>
                            </div>
                        ))}
                    </Slider>




                    <div className="section-full book-form overlay-black-dark bg-img-fix p-t30 p-b10 mid" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                        <div className="container">
                            <form className="row" onSubmit={this.submitHandler}>
                                <div className="col-md-4 col-sm-6 col-6 col-lg-2 form-group">
                                    <label>Keywords</label>
                                    <input className="form-control" name="keywords" placeholder="Enter Keywords here" type="text" value={keywords} onChange={this.changeHandler}/>
                                </div>
                                <div className="col-md-4 col-sm-6 col-6 col-lg-2 form-group">
                                    <label>Activity</label>
                                    <select className="form-control" name="activity" value={activity} onChange={this.changeHandler}>
                                        <option value="any">Any</option>
                                        <option value="accomodation">Accomodation</option>
                                        <option value="cultural">Cultural &amp; Thematic Tours</option>
                                        <option value="events">Events</option>
                                        <option value="holiday">Holiday &amp; Seasonal Tours</option>
                                        <option value="tours">Indulgence &amp; Luxury Tours</option>
                                        <option value="sports">Tourism &amp; Outdoor Activites</option>
                                        <option value="eating_out">Eating Out</option>
                                        <option value="car_rentals">Cabs &amp; Car Rentals</option>
                                    </select>
                                </div>
                                <div className="col-md-4 col-sm-6 col-6 col-lg-2 form-group">
                                    <label>Destination</label>
                                    <select className="form-control" name="destination" value={destination} onChange={this.changeHandler}>
                                        <option value="any">Any</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="Tanzania">Tanzania</option>
                                    </select>
                                </div>
                                <div className="col-md-4 col-sm-6 col-6 col-lg-2 form-group">
                                    <label>Find</label>
                                    <button className="site-button btn-block" type="submit">SEARCH</button>
                                </div>
                            </form>
                        </div>
                    </div>


                    {/*<div className="section-full bg-white content-inner-2">
                    {this.state.response}
                    </div>*/}
                    <TopPlaces />


                    {/*<div className="section-full bg-white content-inner-2 bgeffect overlay-black-middle" style={{ backgroundImage: "url(" + bg3 + ")", backgroundSize: 'cover' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="add-area">
                                        <h3>1000 $ for person - 6 nights</h3>
                                        <h2>Discount <span className="text-primary">10-30%</span> Off</h2>
                                        <p>If you’re looking for a truly memorable family break here you find the lowest price on the right hotel for you. It's indescribable. Stay up to date and check out the deals for these trending destinations.</p>
                                        <Link to='' className="site-button white">See Promotion Tours</Link>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6"></div>
                            </div>
                        </div>
                    </div>*/}

                    <Slick />
                    {/*<Activites />*/}
                    <div className="section-full bg-white content-inner dlab-about-1 promotions">
                        <div className="container">
                            <div className="section-head text-black text-center">
                                <h2 className="text-uppercase m-b0">POPULAR ACTIVITIES</h2>
                                <p className="font-18">BEST ACTIVITIES OF LIFETIME </p>
                            </div>
                            <div className="row packages">
                                {popularActivities.map((item, index) => (
                                    <div className="col-md-6 col-xl-3  col-sm-6 m-b20" key={index}>
                                        <div className="dlab-box">
                                            <div className="dlab-media">
                                                <Link to={'./activities'}><img src={item.image} alt="" /> </Link>
                                            </div>
                                            <div className="dlab-info p-a15 border-1">
                                                <h4 className="dlab-title m-t0"><a>{item.title}</a></h4>
                                                <span className="location">{item.subtitle}</span>
                                                <div className="package-content">
                                                    <ul className="package-meta">
                                                        <li><span className="fa fa-calendar"></span> Offers: {item.offers} </li>
                                                    </ul>
                                                    <div className="clearfix">
                                                        <Link to={'/activities'} className="site-button pull-right">View details</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Homepage;